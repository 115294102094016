import React from 'react';
import WineDiscoveryGuide from '../components/promotions/Promotions';

function PromotionsPage() {
  return (
    <div>
      <WineDiscoveryGuide />
    </div>
  );
}

export default PromotionsPage;

import React from "react";
import BeerGuide from "../components/beer/beer";

function BeerPage () {
    return (
        <div>
            <BeerGuide />
        </div>
    );
}

export default BeerPage;
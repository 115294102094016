import React from 'react';
import {  Container } from 'react-bootstrap';

function ContactForm() {
  return (
    <Container>
      <div>
        <h4>Address:</h4>
        <p>1414 Alameda Ave, Burbank, CA 91506</p>
      </div>
      <div>
        <h4>Phone Number:</h4>
        <p>(818) 846-6231</p>
      </div>
      <div>
        
      </div>
      
    </Container>
  );
}

export default ContactForm;
